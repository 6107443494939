.borderColor {
    background-color: #3d3d3d;
    color: #ECE9E1;
}

.home{
    height: 90vh;
}
.link-button {
  margin-left: 5px;
  margin-right: 10px;
}
.linked-in-button:hover{
  background-color: #3d3d3d;
}

.gitButton {
  background-color: #262626;
}
.gitButton:hover{
  background-color: #3d3d3d;
}


.email-button {
  background-color: #ca0000;
  color: white;
}
.email-button:hover{
  background-color: #aa0000;
}


.imageMe{
    max-width: 25%;
    max-height: 25%;
    width: auto\9; /* ie8 */
}

.root {
    height: 100vh;
    background-image: url('../background-website-hd-12.jpg');
}

.site-bg {
    background-image: url('../background-website-hd-12.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
 .site-body{
   margin-left: 25vw;
   margin-right: 25vw;
 }
.resumeButton {
    border: 2px solid transparent; 
    border-color: white; 
    background-color: transparent;
    color: white;
    box-sizing: border-box; 
    grid-area: 1 / 1; /* first row, first column */
    padding: 10px; 
    width: inherit; 
}
.right{
    text-align: right;
}
.left{
    text-align: left;
}
/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

.navbar {
  padding: 10px;
  min-height: 10px;
}

html,

body {

    height: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 3px rgba(0,0,0,.5);
}

.topSpace {
    margin-top: 3%;
}
.bottomSpace {
  margin-bottom: 3%;
}
/* Extra markup and styles for table-esque vertical and horizontal centering */
.site-wrapper {
  display: table;
  width: 100%;
  height: 94%;
  /*height: 100%; */
  /*min-height: 100%;*/
}
.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
  height: 100%;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding: 30px;
}

/*
 * Cover
 */

.cover {
  padding: 0 20px;
}
.cover .btn-lg {
  padding: 10px 20px;
  font-weight: bold;
}

/*
 * Affix and center
 */

@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    position: fixed;
    top: 0;
  }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }

}

/*About skills
/* Make sure that padding behaves as expected */
* 

.skills {
  border-radius: 1rem;
  background-color: #3d3d3d;
  padding: 25px;
  margin: 5px;
  height: 5em;
  width: 10em;
  font-size: 14px;
  white-space: nowrap;
}

/* Project list */
.projectCircle{
  background: #3d3d3d;
  margin: 3vh;
  color: #ECE9E1;
  text-align: center;
}
.projectCircle:hover{
  background: #2e2e2e;
  color: #ECE9E1;
}

.messageBox{
  height: 25vh;
  width: 50vw;
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
  font-size: 15px;
}

